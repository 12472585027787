import React from "react"
import PropTypes from "prop-types"

import Button from "../components/button"
import keyVisual from "../images/kv2.jpg"

import { Reveal } from "react-genie"

const HeroUsage = ({ siteTitle }) => (
  <div
    style={{
      backgroundImage: `url(${keyVisual})`,
      backgroundSize: "cover",
      backgroundPosition: "center"
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "4rem 1rem",
        background:"rgba(0, 0, 0, .3)"
      }}
    >
      <div
        style={{
          position: "relative",
          top: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <div className="hero-absolute-div">
          <Reveal>
            <h1>使い方</h1>
          </Reveal>
          <Reveal delay="100">
            <p>
              バーコードde実棚の使い方をご紹介します。
            </p>
          </Reveal>
          <Reveal  delay="200">
            <a href="https://apps.apple.com/app/id1509177299/" target="_blank" style={{ color: "white" }}>
              <Button>
                無料ではじめる
              </Button>
            </a>
          </Reveal>
          <Reveal  delay="200">
            <a href="https://tanaoroshi.barpro.co.jp/" target="_blank" style={{ color: "white"}}>
              <Button>
                有料会員になる
              </Button>
            </a>
          </Reveal>
        </div>
      </div>
    </div>
  </div>
)

HeroUsage.propTypes = {
  siteTitle: PropTypes.string,
}

HeroUsage.defaultProps = {
  siteTitle: ``,
}

export default HeroUsage
