import React from "react"

import SectionHeader from "./section-header"
import { Reveal , Animation} from "react-genie"

const VideoUsage = (props) => (
  <div className="video-usage" style={{ 
      padding: "4rem 0 0 0",
      // backgroundColor: "#f2f2f2",
      textAlign:"center"
    }}>
    <div className="video-usage-container">
      <SectionHeader
          title="動画でご紹介"
          description="無料プラン"
        />
      <div style={{margin:"0 auto"}}>
        {/* ↓youtubeの埋め込みリンクをコピペしclassName="video-usage-iframe"をつけて,もともとついてたwidthとheightを消す。allowfullscreenとframeborderをキャメルケースにする */}
        <iframe className="video-usage-iframe" src="https://www.youtube.com/embed/io-HuNCYj4A" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    </div>
  </div>
)

export default VideoUsage
