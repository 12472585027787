import React from "react";


import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import HeroUsage from "../components/hero-usage";
import VideoUsage from "../components/video-usage";
import MainUsage from "../components/main-usage";
import Feature from "../components/feature";
import Point from "../components/point";
import CallToAction from "../components/cta";
import ButtonStartOnly from "../components/button-start-only";
import CustomerVoice from "../components/customer-voice";
import Question from "../components/question";

import {RevealGlobalStyles} from "react-genie";

const Usage = () => (
  <Layout>
    <RevealGlobalStyles />
    <SEO />
    <Header siteTitle="バーコードde実棚 | 使い方"/>
    <HeroUsage />
    <VideoUsage />
    <MainUsage />
  </Layout>
)

export default Usage
