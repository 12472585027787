import React from "react"

import SectionHeader from "./section-header"
import { Reveal , Animation} from "react-genie"
import imgOutputCsv from "../images/usage_output_csv.jpg"
import imgSubmit1 from "../images/usage_submit1.jpg"
import imgSubmit2 from "../images/usage_submit2.jpg"
import imgCheckData1 from "../images/usage_check_data1.jpg"
import imgCheckData2 from "../images/usage_check_data2.jpg"
import imgRegistMaster from "../images/usage_regist_master.jpg"
import imgInputNum from "../images/usage_input_num.jpg"
import imgScanCode from "../images/usage_scan_code.jpg"

const divider = 
  <div style={{marginBottom:"4rem"}}>
    <hr style={{margin:"0 auto"}}></hr>
  </div>
;

const MainUsage = (props) => (
  <div className="main-usage" style={{ 
      padding: "4rem 0 0 0",
      backgroundColor: "#f2f2f2",
      textAlign:"center"
    }}>
    <div className="main-usage-container" style={{marginBottom:"4rem"}}>
      <section className="main-usage-content" style={{border:"1px solid #666", borderRadius:"0.5rem", margin:"0 auto", padding:"1rem"}}>
        <h2>目次</h2>
        <nav>
          <ul style={{listStyleType:"none"}}>
            <li><a href="#main-usage-register-item-master">商品情報の登録</a></li>
            <li><a href="#main-usage-tanaoroshi">棚卸</a></li>
            <li><a href="#main-usage-data">データの確認</a></li>
            <li><a href="#main-usage-send-to-web">Webへ提出</a></li>
            <li><a href="#main-usage-csv">CSVデータを出力</a></li>
          </ul>
        </nav>
      </section>
    </div>
    <div id="main-usage-register-item-master" className="main-usage-container">
      <SectionHeader
          title="商品情報の登録"
          description=""
        />
        <div className="main-usage-content-wrapper">
          <div className="main-usage-content" style={{margin:"0 auto", textAlign:"left"}}>
            <h3 style={{textAlign:"center"}}>有料プラン</h3>
            <ol>
              <li>
                <span>(1) 商品情報をCSVデータで作成</span>
                <ul>
                  <li>Webからテンプレートをダウンロード</li>
                  <li>テンプレートに沿って商品情報を入力</li>
                </ul>
              </li>
              <li>(2) 商品情報をWebからインポート</li>
              <li>(3) 端末に反映</li>
              <li style={{textAlign:"center"}}><img src={imgRegistMaster}></img></li>
            </ol>
          </div>
          <div className="main-usage-content" style={{margin:"0 auto", textAlign:"left"}}>
          <h3 style={{textAlign:"center"}}>無料プラン</h3>
            <ol>
              <li>
                <span>(1) 商品情報を端末に直接入力</span>
              </li>
            </ol>
          </div>
      </div>
    </div>
    {divider}
    <div id="main-usage-tanaoroshi" className="main-usage-container">
      <SectionHeader
          title="棚卸"
          description="無料プラン/有料プラン"
        />
      <div className="main-usage-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li>(1) バーコードをスキャン</li>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgScanCode}></img></li>
          <li>(2) 数量を入力するだけ</li>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgInputNum}></img></li>
        </ol>
      </div>
    </div>
    {divider}
    <div id="main-usage-data" className="main-usage-container">
      <SectionHeader
          title="データの確認"
          description="無料プラン/有料プラン"
        />
      <div className="main-usage-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li>(1) 棚卸データを選択</li>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgCheckData1}></img></li>
          <li>(2) 総計データが一目でわかる</li>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgCheckData2}></img></li>
        </ol>
      </div>
    </div>
    {divider}
    <div id="main-usage-send-to-web" className="main-usage-container">
      <SectionHeader
          title="Webへ提出"
          description="棚卸データはクラウド保存(※有料プランのみ)"
        />
      <div className="main-usage-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li>(1) 棚卸データを選択してWebへ提出</li>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgSubmit1}></img></li>
          <li>(2) Webで各端末の棚卸データを合算</li>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgSubmit2}></img></li>
        </ol>
      </div>
    </div>
    {divider}
    <div id="main-usage-csv" className="main-usage-container">
      <SectionHeader
          title="CSVデータを出力"
          description="端末から出力可能"
        />
      <div className="main-usage-content" style={{margin:"0 auto", textAlign:"left"}}>
        <ol>
          <li style={{listStyle:"none", textAlign:"center"}}><img src={imgOutputCsv}></img></li>
        </ol>
      </div>
    </div>
  </div>
)

export default MainUsage
